@import "src/themes/mojito/styles/index.scss";
.coupon-date-time {
  @include md {
    display: grid;
    grid-gap: space(1);
    grid-template-columns: 1fr 1fr;
  }

  &__timezone-selector {
    width: 100%;
    .Select__wrapper select {
      background-color: white;
    }
  }

  .search-select {
    margin-bottom: space(1);
    &__input {
      height: 45px;
      align-content: center;
    }
    &__icon {
      top: 12px !important;
    }
  }

  .date-picker {
    margin-bottom: space(1);
    input::placeholder {
      font-size: 14px;
    }
  }

  &__date-picker {
    position: relative;
    input {
      padding: space(0.5) space(1);
      height: 45px;
      line-height: 24px;
    }
    .date-picker__input__icon {
      width: 32px;
      height: 32px;
      bottom: -9px;
    }
  }
}
