@import "src/themes/mojito/styles/index.scss";
.hours-selector {
  position: relative;
  width: 109px;
  max-width: 109px;
  cursor: pointer;

  min-height: 1rem;
  padding: space(0.5);
  background-color: white;
  border-radius: 0.25em;
  border: 1px solid getvar($colors, "neutral", "300");

  font-size: 14px;
  outline: 0;

  svg {
    pointer-events: none;
    height: space(1.25);
    width: space(1.25);
    color: getvar($colors, "neutral", "700");
    position: absolute;
    right: 6px;
    top: 8px;
  }

  &:hover {
    border: 1px solid getvar($colors, "neutral", "500");
  }

  &:focus {
    outline: none;
    border: 1px solid getvar($colors, "primary", "500");
  }

  &::-ms-expand {
    display: none;
  }

  &:invalid {
    color: getvar($colors, "neutral", "500");
  }

  @include xs {
    width: 140px;
    max-width: 140px;
  }
  @include md {
    padding: space(0.5) space(1) space(0.5) space(1);

    svg {
      right: 12px;
    }
  }
  &__default {
    color: getvar($colors, "neutral", "800");
  }

  &__placeholder {
    color: getvar($colors, "neutral", "500");
  }

  &--disabled {
    pointer-events: none;

    svg {
      color: getvar($colors, "neutral", "300");
    }

    div {
      color: getvar($colors, "neutral", "300");
      border: 1px solid getvar($colors, "neutral", "300") !important;
    }
  }
}
